<template>
  <div class="talk-sidebar-wrapper">
    <div class="ass-sidebar-top">
      <div class="ai-title">AI会话</div>
      <el-button type="primary" @click="newPrompt" class="new_chat"
        >新建对话</el-button
      >

      <el-input
        placeholder="搜索历史记录"
        prefix-icon="el-icon-search"
        v-model="searchText"
        class="search"
      >
      </el-input>
    </div>

    <el-menu class="els" v-if="visible">
      <el-menu-item
        v-for="([key, value], index) in Array.from(history.entries()).reverse()"
        @click="setDetail(key, value)"
        :key="index"
        v-show="
          searchText == '' ||
          (value[0] &&
            value[0].content &&
            value[0].content.indexOf(searchText) >= 0)
        "
      >
        <i class="el-icon-s-comment"></i>
        <span slot="title"
          >{{
            value[0] && value[0].content && value[0].content.substring(0, 12)
          }}
          <div class="btns">
            <el-link
              :underline="false"
              class="btn"
              @click.prevent="del(key)"
              icon="el-icon-delete"
            ></el-link>
          </div>
        </span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "sidebar",
  data() {
    return {
      searchText: "",
      visible: true,
      history: [],
    };
  },
  methods: {
    newPrompt() {
      this.$emit("newPrompt");
    },
    setDetail(key, value) {
      this.$emit("setDetail", key, JSON.stringify(value));
    },
    setHistoryMap(historyMap) {
      this.visible = false;
      this.history = historyMap;
      this.visible = true;
    },
    del(key) {
      this.history.delete(key);
      let historyMapStr = Array.from(this.history)
        .map(([key, value]) => `${key}_ai_key_${JSON.stringify(value)}`)
        .join("_ai_split_");
      if (historyMapStr) {
        let retrievedMap = new Map();
        historyMapStr.split("_ai_split_").forEach((pair) => {
          let [key, value] = pair.split("_ai_key_");
          retrievedMap.set(key, JSON.parse(value));
        });
        this.history = retrievedMap;
      } else {
        this.history = new Map();
      }

      localStorage.setItem("historyMap", historyMapStr);
      this.$emit("refreshHistory", this.history);
    },
  },
};
</script>

<style lang="less">
.talk-sidebar-wrapper {
  width: 280px;
  height: 100% !important;
  min-height: 600px;
  .ass-sidebar-top {
    padding: 5px 15px;
    .ai-title {
      margin: 10px;

      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #000000;
      line-height: 33px;
      margin-bottom: 30px;
    }
    .search {
      padding: 20px;
      .el-input__prefix {
        left: 15px;
      }
      .el-input__inner {
        border-left: none !important;
        border-right: none !important;
        border-radius: 0px !important;
        height: 55px;
        line-height: 55px;
        background: transparent;
        border-top: 1px solid #d8d8d8;
        border-bottom: 1px solid #d8d8d8;
      }
    }
    .new_chat {
      align-items: center;
      border-radius: 7px;
      color: #fff !important;
      cursor: pointer;
      display: flex;
      height: 40px;
      justify-content: center;
      margin-left: 20px;
      overflow: hidden;
      padding: 1px;
      margin: 20px;
      width: 200px;
      font-size: 16px;
    }
  }

  .el-menu {
    height: calc(100vh - 285px) !important;
    min-height: 400px;
    overflow-x: hidden;
    background-color: transparent !important;
    border-right: solid 0px #e6e6e6;
  }
  background: white;
  background-size: cover;

  .el-submenu__title:hover {
    background-color: #e6e9eb;
  }
  .el-submenu {
    text-align: left;
  }
  .el-menu-item {
    text-align: left;
    height: 48px;
    line-height: 48px;
    i,
    span {
      color: #666;
    }
    &:focus {
      background-color: rgba(0, 0, 0, 0);
    }
    &:hover {
      // background-color: #e6e9eb;
      i,
      span {
        color: #000;
      }
    }
  }

  .els {
    position: relative;
    overflow-y: auto;
    background: transparent;
    height: calc(100% - 400px);
  }
  .els .el-menu-item:hover {
    background-color: #e6e9eb;
    background: hsla(0, 0%, 100%, 0.9);
    border-radius: 8px;
    box-shadow: 0 16px 20px 0 rgba(174, 167, 223, 0.06);
    .btns {
      display: block;
    }
  }
  .els .el-menu-item {
    position: relative;
    .btns {
      position: absolute;
      background-color: #fff;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
      height: 100%;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      display: none;
    }
    margin: 5px;
    .btn {
      align-items: center;
      /* background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff 12%); */
      /* display: flex; */
      /* height: 50px; */
      // justify-content: flex-end;
      /* opacity: 0; */
      right: 0;
      top: 0;
      font-size: 12px;
      /* width: 108px; */
    }
    .btn {
      i:hover {
        color: red;
      }
    }
  }
  .el-submenu__title i {
    color: #909399 !important;
  }
}
</style>
