import request from "@/utils/httpRequest";

export default {
  save: function (inputForm) {
    return request({
      url: "/front/gpt/chatSample/save",
      method: "post",
      data: inputForm,
    });
  },

  delete: function (ids) {
    return request({
      url: "/front/gpt/chatSample/delete",
      method: "delete",
      params: { ids: ids },
    });
  },

  queryById: function (id) {
    return request({
      url: "/front/gpt/chatSample/queryById",
      method: "get",
      params: { id: id },
    });
  },

  list: function (params) {
    return request({
      url: "/front/gpt/chatSample/list",
      method: "get",
      params: params,
    });
  },

  exportTemplate: function () {
    return request({
      url: "/front/gpt/chatSample/import/template",
      method: "get",
      responseType: "blob",
    });
  },

  exportExcel: function (params) {
    return request({
      url: "/front/gpt/chatSample/export",
      method: "get",
      params: params,
      responseType: "blob",
    });
  },

  importExcel: function (data) {
    return request({
      url: "/front/gpt/chatSample/import",
      method: "post",
      data: data,
    });
  },
};
