var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"talk-sidebar-wrapper"},[_c('div',{staticClass:"ass-sidebar-top"},[_c('div',{staticClass:"ai-title"},[_vm._v("AI会话")]),_c('el-button',{staticClass:"new_chat",attrs:{"type":"primary"},on:{"click":_vm.newPrompt}},[_vm._v("新建对话")]),_c('el-input',{staticClass:"search",attrs:{"placeholder":"搜索历史记录","prefix-icon":"el-icon-search"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),(_vm.visible)?_c('el-menu',{staticClass:"els"},_vm._l((Array.from(_vm.history.entries()).reverse()),function(ref,index){
var key = ref[0];
var value = ref[1];
return _c('el-menu-item',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.searchText == '' ||
        (value[0] &&
          value[0].content &&
          value[0].content.indexOf(_vm.searchText) >= 0)
      ),expression:"\n        searchText == '' ||\n        (value[0] &&\n          value[0].content &&\n          value[0].content.indexOf(searchText) >= 0)\n      "}],key:index,on:{"click":function($event){return _vm.setDetail(key, value)}}},[_c('i',{staticClass:"el-icon-s-comment"}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(value[0] && value[0].content && value[0].content.substring(0, 12))+" "),_c('div',{staticClass:"btns"},[_c('el-link',{staticClass:"btn",attrs:{"underline":false,"icon":"el-icon-delete"},on:{"click":function($event){$event.preventDefault();return _vm.del(key)}}})],1)])])}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }